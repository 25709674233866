import React, { Component } from "react";
import { Form, Button, Modal, Icon } from "semantic-ui-react";
import { uploadFile } from "../../../../../BytebeamClient";
import styled from "styled-components";
import AnimatedEllipsis from "../../../common/AnimatedEllipsis";
import { beamtoast } from "../../../../common/CustomToast";
import { StyledFileInput } from "./SendFileModal";
import AdvanceSettingsSection from "../../../Actions/NewAction/AdvanceSettingsSection";
import { ThinDivider } from "../../../Dashboards/Panel/util";

type SendScriptModalProps = {
  isOpen: boolean;
  close: (...args: any[]) => any;
  triggerAction: (
    actionType: string,
    params: { id?: string; version?: string },
    requestApproval?: boolean
  ) => any;
  allSelected: boolean;
  selectedDevicesCount: number;
  endTimestamp: Date | number;
  updateActionTimeout: (timeout: Date | number) => void;
  updateAutoRetry: (retryUntil: number | Date) => void;
};

type SendScriptModalState = {
  status: number;
  file: File;
  fileName: string;
  isUploading: boolean;
  showUploadProgress: boolean;
  fileLoaded: number;
  fileTotal: number;
  uploadedFileResponse: {
    status: number;
    data: { id: string };
  };
};

export const StyledScriptUploadButton = styled(Button)`
  position: relative;
  width: 100%;
  margin-top: 8px;
  background: ${(props) =>
    props.theme.colors["select_file_button-background"]} !important;
  color: ${(props) =>
    props.theme.colors["select_file_button-color"]} !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  text-align: center !important;
  border: ${(props) =>
    props.theme.colors["select_file_button-border"]} !important;
  border-radius: 4px;
  cursor: pointer;
`;

enum SendScriptStatus {
  UploadScript,
  ConfirmSendScript,
}

export class SendScriptModal extends Component<
  SendScriptModalProps,
  SendScriptModalState
> {
  state = {
    status: SendScriptStatus.UploadScript,
    file: new File([""], "filename"),
    fileName: "",
    isUploading: false,
    showUploadProgress: false,
    fileLoaded: 0,
    fileTotal: 0,
    uploadedFileResponse: {
      status: 0,
      data: { id: "" },
    },
  };

  fileUpload = async (file: File, fileName: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    try {
      const url = `/api/v1/file`;
      const res = await uploadFile(url, formData, (p) => {
        this.setState({
          fileLoaded: p.loaded,
          fileTotal: p.total,
        });
      });
      this.setState({ uploadedFileResponse: res });
    } catch (error) {
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413")) {
        beamtoast.error("Upload failed due to size limit!");
      } else if (String(error).includes("401")) {
        beamtoast.error("Upload failed as access is not there!");
      } else beamtoast.error("Failed to upload File");
    }
  };

  onSelect = (e) => {
    if (e.target.files.length !== 0) {
      this.setState({
        file: e.target.files[0],
        fileName: e.target.files[0].name,
        showUploadProgress: true,
      });
      this.fileUpload(e.target.files[0], e.target.files[0].name);
    }
  };

  triggerSendFileAction = async (requestApproval: boolean) => {
    try {
      if (this.state.uploadedFileResponse.status === 201) {
        await this.props.triggerAction(
          "send_script",
          {
            id: this.state.uploadedFileResponse?.data.id,
          },
          requestApproval
        );
      }
    } catch (error) {
      beamtoast.error("Failed to upload Script");
    }
  };

  renderFileUpload() {
    return (
      <Form>
        <Form.Field>
          <label>Upload Script from the system: </label>
          <div style={{ position: "relative" }}>
            <StyledScriptUploadButton
              fluid
              content="Select Script"
              labelPosition="left"
              icon="file alternate outline"
            />
            <StyledFileInput type="file" id="file" onChange={this.onSelect} />
            {/* accept=".sh"  */}
          </div>
          <label style={{ marginTop: "12px" }}>Script Chosen: </label>
          {this.state.fileName ? this.state.fileName : "No Script Chosen"}
        </Form.Field>

        {this.state.showUploadProgress && (
          <Form.Field>
            <label htmlFor="file-progress">
              {this.state.uploadedFileResponse.status === 0 ? (
                <span>
                  File Uploading
                  <AnimatedEllipsis
                    spacing={3}
                    dotSize={"8px"}
                    dotColor={"#ffffff"}
                  />
                </span>
              ) : (
                <span>File Uploaded</span>
              )}
            </label>
            <progress
              id="file-progress"
              max={this.state.fileTotal}
              value={this.state.fileLoaded}
            />
          </Form.Field>
        )}
        <ThinDivider style={{ margin: "18px 0px" }} />
        <AdvanceSettingsSection
          endTimestamp={this.props.endTimestamp}
          releaseNotes={""}
          action={"send_script"}
          setEndTimestamp={this.props.updateActionTimeout}
          setReleaseNotes={() => console.log("Not implemented")}
          setRetryUntil={this.props.updateAutoRetry}
        />
      </Form>
    );
  }

  renderConfirmSendFile() {
    return (
      <Modal.Description>
        <div style={{ fontSize: "16px", marginBottom: "16px" }}>
          Are you ready to send <b>{this.state.fileName}</b> file to{" "}
          <b>
            {this.props.allSelected
              ? // ? `All (${this.props.selectedDevicesCount} devices)` TODO: Fix this once device count on search is available
                `All devices`
              : `${
                  this.props.selectedDevicesCount === 1
                    ? "1 device"
                    : `${this.props.selectedDevicesCount} devices`
                }`}
          </b>{" "}
          ?
        </div>
        This action will send script file to all selected devices. Please click
        on <b>Send</b> button if you would like to proceed, else click on{" "}
        <b>Discard</b> button.
      </Modal.Description>
    );
  }

  renderContents() {
    switch (this.state.status) {
      case SendScriptStatus.UploadScript:
        return this.renderFileUpload();

      case SendScriptStatus.ConfirmSendScript:
        return this.renderConfirmSendFile();

      default:
        return this.renderFileUpload();
    }
  }

  // Reset state and close modal
  onCloseModal = () => {
    this.setState({
      status: SendScriptStatus.UploadScript,
      file: new File([""], "filename"),
      fileName: "",
      isUploading: false,
      showUploadProgress: false,
      fileLoaded: 0,
      fileTotal: 0,
      uploadedFileResponse: {
        status: 0,
        data: { id: "" },
      },
    });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;
    // Next button is disabled if file is not uploaded
    const disableNextButton = this.state.uploadedFileResponse.status !== 201;

    return (
      <Modal
        open={isOpen}
        onClose={this.onCloseModal}
        size="tiny"
        className="dark"
      >
        <Modal.Header>Send Script</Modal.Header>
        <Modal.Content>{this.renderContents()}</Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              this.onCloseModal();
              this.setState({ status: SendScriptStatus.UploadScript });
            }}
            style={{
              float: "left",
            }}
          >
            <Icon name="remove" /> Discard
          </Button>
          {this.state.status === SendScriptStatus.ConfirmSendScript && (
            <Button
              secondary
              disabled={disableNextButton}
              onClick={() => {
                this.triggerSendFileAction(true);
                this.onCloseModal();
              }}
            >
              <Icon name="envelope outline" /> Request
            </Button>
          )}
          <Button
            disabled={disableNextButton}
            primary
            onClick={() => {
              if (this.state.status === SendScriptStatus.UploadScript) {
                this.setState({ status: SendScriptStatus.ConfirmSendScript });
              }
              if (this.state.status === SendScriptStatus.ConfirmSendScript) {
                this.triggerSendFileAction(false);
                this.onCloseModal();
              }
            }}
          >
            {this.state.status === SendScriptStatus.UploadScript ? (
              <>
                Next <Icon name="angle right" />
              </>
            ) : (
              <>
                <Icon name="checkmark" /> Trigger
              </>
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
