import React, { Component } from "react";
import { fetchAllDeviceConfiguration } from "../../../../../BytebeamClient";
import { Modal, Dropdown, Button, Icon } from "semantic-ui-react";
import { beamtoast } from "../../../../common/CustomToast";
import AdvanceSettingsSection from "../../../Actions/NewAction/AdvanceSettingsSection";
import { ThinDivider } from "../../../Dashboards/Panel/util";

type UpdateConfigModalProps = {
  isOpen: boolean;
  close: (...args: any[]) => any;
  triggerAction: (
    actionType: string,
    params: { id?: string; version?: string },
    requestApproval?: boolean
  ) => any;
  allSelected: boolean;
  selectedDevicesCount: number;
  action_type: string;
  endTimestamp: Date | number;
  updateActionTimeout: (timeout: Date | number) => void;
  updateAutoRetry: (retryUntil: number | Date) => void;
};

export type DropdownOptionType = {
  key: string;
  value: string;
  text: string;
};

type UpdateConfigModalState = {
  options: DropdownOptionType[];
  selectedOption: string;
  status: number;
  configs: { [key: string]: any };
  loadingDropdown: boolean;
};

enum UpdateConfigStatus {
  SelectDeviceConfigVersion,
  TriggerUpdateDeviceConfigVersion,
}

export class UpdateConfigModal extends Component<
  UpdateConfigModalProps,
  UpdateConfigModalState
> {
  state = {
    options: [],
    selectedOption: "",
    status: UpdateConfigStatus.SelectDeviceConfigVersion,
    configs: {},
    loadingDropdown: true,
  };

  async fillDropdownOptions() {
    try {
      const res = await fetchAllDeviceConfiguration();
      const resJSON = res.filter(
        (o) => !o.is_deactivated && o.action_type === this.props.action_type
      );
      const options = resJSON.map((o, index) => {
        return {
          key: `${o.tenant_id}-${index}`,
          value: o.version_name,
          text: o.version_name,
        };
      });

      const configs = resJSON.reduce((acc, config) => {
        acc[config.version_name] = config;
        return acc;
      }, {});

      this.setState({ options, configs });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loadingDropdown: false });
    }
  }

  onSelect = async (e, data) => {
    this.setState({
      selectedOption: data.value,
    });
  };

  // Reset state and close modal
  onCloseModal = () => {
    this.setState({
      options: [],
      selectedOption: "",
      status: UpdateConfigStatus.SelectDeviceConfigVersion,
      loadingDropdown: true,
    });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        open={isOpen}
        onClose={this.onCloseModal}
        size="tiny"
        className="dark"
        onMount={this.fillDropdownOptions.bind(this)}
      >
        <Modal.Header>
          Update{" "}
          {this.props.action_type === "update_geofence" ? "Geofence" : "Device"}{" "}
          Config
        </Modal.Header>
        <Modal.Content>
          {this.state.status ===
            UpdateConfigStatus.SelectDeviceConfigVersion && (
            <Modal.Description>
              <p>
                Select{" "}
                {this.props.action_type === "update_geofence"
                  ? "geofence"
                  : "device"}{" "}
                config version:
              </p>
              <Dropdown
                placeholder="Select Version"
                fluid
                selection
                search
                options={this.state.options}
                onChange={this.onSelect}
                value={this.state.selectedOption}
                loading={this.state.loadingDropdown !== false}
                disabled={this.state.loadingDropdown}
              />
              <ThinDivider style={{ margin: "18px 0px" }} />
              <AdvanceSettingsSection
                endTimestamp={this.props.endTimestamp}
                releaseNotes={""}
                action={this.props.action_type}
                setEndTimestamp={this.props.updateActionTimeout}
                setReleaseNotes={() => console.log("Not implemented")}
                setRetryUntil={this.props.updateAutoRetry}
              />
            </Modal.Description>
          )}
          {this.state.status ===
            UpdateConfigStatus.TriggerUpdateDeviceConfigVersion && (
            <Modal.Description>
              <div style={{ fontSize: "16px", marginBottom: "16px" }}>
                Are you ready to deploy{" "}
                <b>
                  {this.props.action_type === "update_geofence"
                    ? "geofence"
                    : "device"}{" "}
                  config version {this.state.selectedOption}
                </b>{" "}
                to{" "}
                <b>
                  {this.props.allSelected
                    ? // ? `All (${this.props.selectedDevicesCount} devices)` TODO: Fix this once device count on search is available
                      `All devices`
                    : `${
                        this.props.selectedDevicesCount === 1
                          ? "1 device"
                          : `${this.props.selectedDevicesCount} devices`
                      }`}
                </b>{" "}
                ?
              </div>
              This action will update the config on all selected devices. Please
              click on <b>Update</b> button if you would like to proceed with
              the deployment, else click on <b>Discard</b> button.
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => this.onCloseModal()}
            style={{
              float: "left",
            }}
          >
            <Icon name="remove" /> Discard
          </Button>
          {this.state.status ===
            UpdateConfigStatus.TriggerUpdateDeviceConfigVersion && (
            <Button
              secondary
              onClick={() => {
                this.props.triggerAction(
                  this.props.action_type,
                  {
                    version: this.state.selectedOption,
                  },
                  true
                );
                this.onCloseModal();
              }}
            >
              <Icon name="envelope outline" /> Request
            </Button>
          )}
          <Button
            primary
            onClick={() => {
              if (
                this.state.status ===
                UpdateConfigStatus.SelectDeviceConfigVersion
              ) {
                if (this.state.selectedOption === "") {
                  beamtoast.error("Please select a Config version");
                } else {
                  this.setState({
                    status: UpdateConfigStatus.TriggerUpdateDeviceConfigVersion,
                  });
                }
              } else {
                this.props.triggerAction(this.props.action_type, {
                  version: this.state.selectedOption,
                });
                this.onCloseModal();
              }
            }}
          >
            {this.state.status ===
            UpdateConfigStatus.SelectDeviceConfigVersion ? (
              <>
                Next <Icon name="angle right" />
              </>
            ) : (
              <>
                <Icon name="checkmark" /> Trigger
              </>
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
