import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useUser } from "../../../context/User.context";
import { AlertGroups, AlertRule } from "../../../util";
import { DashboardsInfo } from "../Dashboards/ViewDashboard";
import {
  fetchAlertGroups,
  fetchAlertRules,
  fetchAllDashboards,
} from "../../../BytebeamClient";
import useAsyncEffect from "../common/useAsyncEffect";
import { ErrorMessage } from "../../common/ErrorMessage";
import { DisplayIf } from "../util";
import ActionsMenuTab from "../Actions/ActionsV3/ActionsMenuTab";
import { ActionsMenuWrapper } from "../Actions/ActionsV3/ActionsV3";
import LoadingAnimation from "../../common/Loader";
// import AlertsDashboard from "./AlertsDashboard/AlertsDashboard";
import AlertRules from "./AlertRules/AlertRules";
import AlertNotificationRules from "./NotificationRules/AlertNotificationRules";

export default function Alerts() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const [alertRules, setAlertRules] = useState<AlertRule[]>([]);
  const [alertGroups, setAlertGroups] = useState<AlertGroups>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [newAlertRuleName, setNewAlertRuleName] = useState<string>("");
  const [activeItem, setActiveItem] = useState<string>("Alert Rules");
  const [dashboards, setDashboards] = useState<DashboardsInfo[]>([]);

  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  useEffect(() => {
    let activeItem: string;

    switch (location.pathname) {
      // case `${path}/alerts-dashboard`:
      //   activeItem = "Alerts Dashboard";
      //   break;

      case `${path}/alert-rules`:
        activeItem = "Alert Rules";
        break;

      case `${path}/alert-notification`:
        activeItem = "Notification Settings";
        break;

      default:
        // activeItem = "Alerts Dashboard";
        activeItem = "Alert Rules";
        break;
    }

    handleItemClick(activeItem);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdate = async (showLoading: boolean) => {
    showLoading && setLoading(true);
    try {
      const res = await fetchAlertRules();
      setAlertRules(res);
      const alertGroups = await fetchAlertGroups();
      setAlertGroups(alertGroups);
    } catch (error) {
      console.log(error);
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  };

  const getDashboards = async () => {
    try {
      const res = await fetchAllDashboards();
      const userIdString = "user:" + user.id || "xx"; // Replace 'user.id' with the actual current user's ID
      const roleString = "role:" + user.role.id || "xx"; // Replace 'user.role' with the actual current user's role
      const filteredDashboards = res
        .filter((dashboard) => {
          const hideFromAdmins =
            dashboard?.config?.dashboardMeta?.hideFromAdmins ?? false;
          const owners = dashboard?.config?.dashboardMeta?.owners ?? [];
          const viewers = dashboard?.config?.dashboardMeta?.viewers ?? [];

          if (hideFromAdmins) {
            return (
              owners.includes(userIdString) ||
              owners.includes(roleString) ||
              viewers.includes(userIdString) ||
              viewers.includes(roleString)
            );
          } else {
            return true;
          }
        })
        .map((dashboard) => {
          return {
            id: dashboard.id,
            title: dashboard.config.dashboardMeta.title,
            type: dashboard.config.dashboardMeta.type,
          };
        });
      setDashboards(filteredDashboards);
    } catch (e) {
      console.log(e);
    }
  };

  useAsyncEffect(async () => {
    document.title = "Alerts | Bytebeam";

    await getDashboards();
    await handleUpdate(true);
  }, []);

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  let loadingText = "Loading Alerts...";
  if (location.pathname.includes("alert-rules")) {
    loadingText = "Loading Alert Rules";
  } else if (location.pathname.includes("alert-notification")) {
    loadingText = "Loading Notification Rules";
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="79vh"
        fontSize="1.5rem"
        loadingText={loadingText}
        marginTopText="12px"
      />
    );
  }

  return (
    <>
      <ActionsMenuWrapper className="desktop-view">
        {/* <DisplayIf cond={permissions.viewAlerts ?? true}>
          <ActionsMenuTab
            firstElement
            name={"Alerts Dashboard"}
            icon={"list alternate outline"}
            to={`${path}/alerts-dashboard`}
            displayIf={true}
            active={activeItem === "Alerts Dashboard"}
            onClick={() => {
              handleItemClick("Alerts Dashboard");
            }}
          />
        </DisplayIf> */}
        <DisplayIf cond={permissions.viewAlerts ?? true}>
          <ActionsMenuTab
            firstElement
            // firstDivider
            name={"Alert Rules"}
            icon={"wait"}
            to={`${path}/alert-rules`}
            displayIf={true}
            active={activeItem === "Alert Rules"}
            onClick={() => {
              handleItemClick("Alert Rules");
            }}
          />
        </DisplayIf>
        <DisplayIf cond={permissions.viewNotifications ?? true}>
          <ActionsMenuTab
            firstDivider
            lastElement
            name={"Notification Settings"}
            icon={"boxes"}
            to={`${path}/alert-notification`}
            displayIf={true}
            active={activeItem === "Notification Settings"}
            onClick={() => {
              handleItemClick("Notification Settings");
            }}
          />
        </DisplayIf>
      </ActionsMenuWrapper>

      <Switch>
        {/* <Route exact path={`${path}/alerts-dashboard`}>
          <DisplayIf cond={permissions.viewAlerts ?? true}>
            <AlertsDashboard
              alertRules={alertRules}
              alertGroups={alertGroups}
              dashboards={dashboards}
            />
          </DisplayIf>
        </Route> */}

        <Route exact path={`${path}/alert-rules`}>
          <DisplayIf cond={permissions.viewAlerts ?? true}>
            <AlertRules
              alertRules={alertRules}
              alertGroups={alertGroups}
              setLoading={setLoading}
              onChange={handleUpdate}
              newAlertRuleName={newAlertRuleName}
              setNewAlertRuleName={setNewAlertRuleName}
              dashboards={dashboards}
            />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/alert-notification`}>
          <DisplayIf cond={permissions.viewNotifications ?? true}>
            <AlertNotificationRules
              alertRules={alertRules}
              onChange={handleUpdate}
            />
          </DisplayIf>
        </Route>
        {/* <Redirect exact from={path} to={`${path}/alerts-dashboard`} /> */}
        <Redirect exact from={path} to={`${path}/alert-rules`} />
      </Switch>
    </>
  );
}
