import { GridLayout } from "./components/Screens/Dashboards/DashboardBody";
import {
  SerializedTimeRange,
  TimeRange,
} from "./components/Screens/Dashboards/Datetime/TimeRange";
import {
  DashboardMeta,
  DashboardType,
} from "./components/Screens/Dashboards/EditDashboardModal";
import { PanelMetaData } from "./components/Screens/Dashboards/Panel/PanelDef";
import {
  PanelData,
  getPanelDefForPanelType,
} from "./components/Screens/Dashboards/Panel/util";
import {
  Role,
  User,
  Settings,
  IUserResult,
  Permission,
  IUser,
  ActionType,
  Auth,
  timeoutDelay,
  ApiKey,
  TenantSettings,
  SessionType,
  AlertRule,
  UserSettings,
  urlToDocId,
  AlertNotificationRule,
  AllDBCResponse,
  SingleDBCResponse,
  DeleteDBCResponse,
  DBCFileResponse,
  ParsedDBCResponse,
  ProgressHistogramStats,
  AtLeastOnePropRequired,
  EditDBCParserInput,
  Condition,
  convertToURLParams,
  AlertGroups,
  DBCResponse,
} from "./util";
import axios, { AxiosResponse } from "axios";
import { objectToQuery } from "./components/Screens/common/QuerySelector";
import { Mixpanel } from "./components/Screens/common/MixPanel";
import { SemanticICONS } from "semantic-ui-react";
import { beamtoast } from "./components/common/CustomToast";
import { ActionFilterOptions } from "./components/Screens/Actions/util";
import { NewCreateActionPhaseSchema } from "./components/Screens/DeviceManagement/Devices/Devices";

export function getTenantFromURL(url?: string) {
  try {
    let currentURL: URL;

    if (url) currentURL = new URL(url);
    else currentURL = new URL(window.location.href);

    const path = currentURL.href.replace(currentURL.origin, "");
    let tenant = path.split("/");
    if (tenant.length > 2 && tenant[2] && tenant[1] === "projects") {
      return tenant[2];
    } else {
      return "";
    }
  } catch (e) {
    console.error(`Error in URL parsing for tenant: ${e}`);
    return "";
  }
}

export function getKeyFromPath(path) {
  // Extracting the main part of the path not params or hash
  const mainPath = path.split("?")[0].split("#")[0];

  // Finding the right key in urlToDocId
  for (const key in urlToDocId) {
    if (mainPath.includes(`/${key}`)) {
      return key;
    }
  }

  // If no key is found, it's not available
  return "na";
}

export function getPathFromURL() {
  try {
    const currentURL = new URL(window.location.href);
    const path = currentURL.pathname; // This gets you just the path portion of the URL

    //we'll extract the right key from this path
    const key = getKeyFromPath(path);
    return key;
  } catch (e) {
    console.error(`Error in URL parsing for path: ${e}`);
    return "";
  }
}

function shouldSkipAccessDeniedRouting(url: string, method?: string): boolean {
  // Default to "get" if method is undefined
  const effectiveMethod = method ? method.toLowerCase() : "get";

  const excludedUrls = [
    { path: "action-types", method: "get" },
    { path: "action-types", method: "post" },
    { path: "firmwares", method: "get" },
    { path: "firmware-bundles", method: "get" },
    { path: "device-configs", method: "get" },
    { path: "alert-rules", method: "get" },
    { path: "alert-groups", method: "get" },
    // Add more URLs and their respective methods as needed
    // { path: "another-url", method: "post" },
  ];

  return excludedUrls.some(
    (excluded) =>
      url.includes(excluded.path) && effectiveMethod === excluded.method
  );
}

export async function apiCall(
  url: string,
  withTenantId: boolean,
  params: any = {}
): Promise<any> {
  const tenant = getTenantFromURL();
  let headers = params?.headers ?? {};
  let h = headers;

  if (withTenantId && tenant) {
    h = { ...headers, "X-Bytebeam-Tenant": tenant };
  }

  let p = params ? { ...params, headers: h } : { headers: h };

  try {
    const res = await fetch(url, p);

    if (!res.status.toString().startsWith("2")) {
      if (res.status === 401 || res.status === 403) {
        // Check if we should skip error handling for this URL and method
        if (shouldSkipAccessDeniedRouting(url, p.method)) {
          // Its to handle if there are multiple RBAC controlled API calls and even if one fails, we don't want to show access denied
          // Eg. Action types API call is used in device mgmt and if it fails, we don't want to show access denied to whole page
        } else {
          beamtoast.error("Access Denied!");
          Mixpanel.track("Session Timeout");
          await timeoutDelay(200);
          window.location.assign(`/projects/${tenant}/accessDenied`);
        }
      } else if (res.status === 400) {
        let body = await res.json();
        let msg = body["error"];
        if (typeof msg === "string") {
          let err = new Error(msg);
          err.name = "bad_request";
          throw err;
        }
      } else {
        // No toast here, to ignore unnecessary error display.
        console.error(
          "Oops, something went wrong! Contact support@bytebeam.io "
        );
      }
      throw new Error(`Fetch failed with status ${res.status}`);
    } else if (res.status !== 204) {
      const contentType = res.headers.get("content-type");
      if (!contentType) {
        return res;
      }
      if (contentType.indexOf("application/json") !== -1) {
        return res.json();
      }
      if (contentType.indexOf("text/plain") !== -1) {
        return res.text();
      } else {
        console.warn("Unknown content type: ", contentType);
        return res;
      }
    } else {
      return res;
    }
  } catch (error) {
    console.error("Error occurred in apiCall: ", error);
    throw error;
  }
}

export type Key = {
  key: string;
};

export type ActionDropdownType = {
  text: string;
  value: string;
  icon: SemanticICONS;
  payload_type: string;
  json_schema?: string;
  json_ui_schema?: string;
};

export type Stream = {
  fields: string[];
  computedFrom?: {
    stream: string;
    lang: string;
    code: string;
    source: string;
  };
};

export type DeviceFilterOption = {
  filterName: string;
  filterValues: string[];
};

export async function fetchAllRoles(): Promise<Role[]> {
  const res = await apiCall("/api/v1/roles", true);
  return res.result;
}

export async function fetchAllLogTags(
  table: string
): Promise<AxiosResponse<{ tags: string[] }>> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/log-tags?table=${table}`;

  const res = axios.request({
    method: "GET",
    url: encodeURI(url),
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });

  return res;
}

export async function fetchAllActionTypes(): Promise<ActionType[]> {
  const res = await apiCall("/api/v1/action-types", true);
  return res;
}

export async function fetchAllStreams(): Promise<{ result: string[] }> {
  const res = await apiCall("/api/v1/streams", true);
  return res;
}

export type StreamFieldDetails = {
  type: string;
  required: boolean;
  seq_id: number;
  unit: string | null;
};

export type StreamFields = { [key: string]: StreamFieldDetails };

export type StreamDetails = {
  fields: StreamFields;
};

export type FetchStreamsAPIResponse = { [key: string]: StreamDetails };

export async function fetchAllStreamsWithDetails(): Promise<FetchStreamsAPIResponse> {
  const res = await apiCall("/api/v1/streams/detailed", true);
  return res.streams;
}

export async function fetchDeviceFilterOptions(
  filters: Filters
): Promise<DeviceFilterOption[]> {
  if (filters.state) {
    delete filters.state;
  }

  const query = convertToURLParams(filters);
  const res = await apiCall(
    `/api/v1/devices/filter-options?status=active&${query}`,
    true
  );

  return res;
}

export type MetadataType = {
  id: any;
  key: string;
  value: string | null;
};

export async function fetchAllMetadataKeys(): Promise<{ key: string }[]> {
  const res = await apiCall(`/api/v1/devices/metadata-keys`, true);
  return res;
}

// Mark serial key api req
export async function markMetadataSerialKey(metadataKey: string): Promise<any> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/devices/metadata-keys/${encodeURIComponent(metadataKey)}/mark-as-serial`;

  const res = await axios.request({
    method: "PUT",
    url: encodeURI(url),
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });
  return res;
}

// Unmark serial key api req
export async function unmarkMetadataSerialKey(
  metadataKey: string
): Promise<any> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/devices/metadata-keys/${encodeURIComponent(metadataKey)}/unmark-as-serial`;
  const res = await axios.request({
    method: "PUT",
    url: encodeURI(url),
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });
  return res;
}

export type DashboardAPIResponse = {
  timeRange: TimeRange;
  id: number;
  config: {
    timeRange: SerializedTimeRange;
    panels: PanelMetaData[];
    dashboardMeta: DashboardMeta;
    gridLayouts: GridLayout;
  };
};

export type PanelDataMap = { [key: string]: PanelData<PanelMetaData, {}> };

export async function fetchDashboard(id: number): Promise<Dashboard> {
  const url = `/api/v1/dashboards/${id}`;
  const dashboard = await apiCall(url, true);

  const panels: PanelDataMap = {};
  let gridLayouts = {
    lg: [],
    xs: [],
  };

  if (dashboard.config) {
    (dashboard.config.panels || [])
      .filter((panel) => panel?.id)
      .forEach((panel: PanelMetaData) => {
        panels[panel.id] = {
          meta: panel,
          panelDef: getPanelDefForPanelType(panel.type),
          isDirty: false,
          loading: false,
          error: false,
        };
      });

    gridLayouts = dashboard.config.gridLayouts || {
      lg: [],
      xs: [],
    };
  }

  return {
    id: dashboard.id,
    dashboardMeta: dashboard.config ? dashboard.config.dashboardMeta : {},
    gridLayouts: gridLayouts,
    panels: panels,
    isDirty: false,
  };
}

export type TableInfo = {
  [key: string]: Array<{
    name: string;
    type: string;
    seq_id: number;
    required: boolean;
    unit: string | null;
  }>;
};

export async function fetchTableInfo(): Promise<TableInfo> {
  const res = await apiCall("/api/v1/stream_info", true);
  return res;
}

export type Filters = {
  [key: string]: string[];
};

export type Dashboard = {
  id: number;
  isDirty: boolean;
  panels: PanelDataMap;
  dashboardMeta: DashboardMeta;
  gridLayouts: GridLayout;
};

export type DeviceMetadata = Record<string, string | number>;

export type Device = {
  id: number;
  "-serial_metadata"?: { [key: string]: string };
  status: string;
  metadata: DeviceMetadata;
  state: {
    timestamp: string;
    sequence: number;
    Status: string;
  };
};

export type SearchDeviceResponse = {
  devices: Device[];
  count: number;
};

export const devicesPerPageOptions = [
  {
    key: 5,
    text: 5,
    value: 5,
  },
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 25,
    text: 25,
    value: 25,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 100,
    text: 100,
    value: 100,
  },
];

export const rowsPerPageOptions = [
  {
    key: 5,
    text: 5,
    value: 5,
  },
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 20,
    text: 20,
    value: 20,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 100,
    text: 100,
    value: 100,
  },
];

export type searchDevicesFilters = {
  metaDatafilters?: Record<string, string | string[]>;
  stateFilters?: Condition[];
};

export async function searchDevices(
  searchFilters: AtLeastOnePropRequired<searchDevicesFilters>,
  page: number,
  limit: number,
  status: string = "active",
  abortSignal: AbortSignal | null = null
): Promise<SearchDeviceResponse> {
  try {
    const requestBody = {
      state: searchFilters?.stateFilters,
      metadata: searchFilters?.metaDatafilters,
      page: page,
      limit: limit,
      status: status,
    };

    const res = await apiCall(`/api/v1/devices/search`, true, {
      method: "POST",
      // JSON.stringify excludes the undefined value props in this case it filters out state/metadata when undefined.
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });

    return res;
  } catch (err) {
    throw err;
  }
}

export async function searchDevicesWithSpecificAction(
  filters: Filters,
  page: number,
  limit: number,
  actionID: number,
  status: string = "active",
  abortSignal: AbortSignal | null = null
): Promise<SearchDeviceResponse> {
  try {
    const requestBody = {
      metadata: filters,
      page: page,
      limit: limit,
      status: status,
      "action-id": actionID,
    };

    const res = await apiCall(`/api/v1/devices/search`, true, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });

    return res;
  } catch (err) {
    console.error("Error occurred in searchDevicesWithSpecificAction.");
    throw err;
  }
}

export async function fetchDevice(
  id: number,
  abortSignal: AbortSignal | null = null
): Promise<Device> {
  try {
    const devices = await searchDevices(
      { metaDatafilters: { id: [id.toString()] } },
      1,
      1,
      "all",
      abortSignal
    );
    return devices.devices[0];
  } catch (err) {
    console.error("Error occurred in fetchDevice.");
    throw err;
  }
}

export async function fetchDeviceWithSpecificAction(
  id: number,
  actionID: number,
  abortSignal: AbortSignal | null = null
): Promise<Device> {
  try {
    const devices = await searchDevicesWithSpecificAction(
      { id: [id.toString()] },
      1,
      1,
      actionID,
      "all",
      abortSignal
    );
    return devices.devices[0];
  } catch (err) {
    console.error("Error occurred in fetchDeviceWithSpecificAction.");
    throw err;
  }
}

export async function createTenant(
  tenantId: string,
  hardware_type: string
): Promise<any> {
  const res = await apiCall(encodeURI(`/api/v1/tenants`), false, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "tenant-id": tenantId,
      "device-type": hardware_type.toLowerCase(),
    }),
  });
  return res;
}

export async function fetchCurrentUser(): Promise<User> {
  const tenant = getTenantFromURL();
  let h = { "X-Bytebeam-Tenant": tenant };

  let p = { headers: h };
  const res = await fetch("/api/v1/me", p);

  if (!res.status.toString().startsWith("2")) {
    throw new Error(`Fetch failed with status ${res.status}`);
  } else if (res.status === 200) {
    const jsonRes = await res.json();
    return jsonRes;
  } else {
    throw new Error("Received non 200 response");
  }
}

export async function fetchSettings(): Promise<Settings> {
  const tenant = getTenantFromURL();
  let h = { "X-Bytebeam-Tenant": tenant };

  let p = { headers: h };
  const res = await fetch("/api/v1/server/settings", p);

  if (!res.status.toString().startsWith("2")) {
    throw new Error(`Fetch failed with status ${res.status}`);
  } else {
    if (res.status === 200) {
      const jsonRes = await res.json();
      return jsonRes;
    } else {
      throw new Error("Received non 200 response");
    }
  }
}

export async function deleteActionType(action: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/action-types/${encodeURIComponent(action)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

export async function deleteMetadataKey(key: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/devices/metadata-keys/${encodeURIComponent(key)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

type TableFieldType = {
  seq_id: number;
  type: string;
  required: string;
};

export type StreamTableFieldsType = {
  [key: string]: TableFieldType;
};

export async function fetchStreamTableFields(
  tableName: string
): Promise<{ result: StreamTableFieldsType }> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(tableName)}/fields`,
    true
  );
  return res;
}

export async function deleteStreamTableField(
  tableName: string,
  fieldName: string
): Promise<any> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(
      tableName
    )}/fields/${encodeURIComponent(fieldName)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

export async function fetchAllUsers(): Promise<IUserResult> {
  const res = await apiCall("/api/v1/users", true);
  return res;
}

export async function deleteUser(userId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/users/${encodeURIComponent(userId)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

export async function deleteRole(roleId: number): Promise<any> {
  const res = await apiCall(
    `/api/v1/roles/${encodeURIComponent(roleId)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

export async function fetchAllApiKeys(): Promise<ApiKey[]> {
  try {
    const res = await apiCall("/api/v1/apikeys", true);
    return res;
  } catch (err) {
    throw err;
  }
}

export async function deleteApiKey(key: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/apikeys/${encodeURIComponent(key)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

export type FirmwareType = {
  device_component_name?: string;
  checksum: string;
  content_length: number;
  uncompressed_size: number;
  tenant_id: string;
  download_url: string;
  download_url_expires_at: Date;
  file_name: string;
  is_deactivated: boolean;
  version_number: string;
  dependencies: FirmwareVersionDependency[];
  upload_status: string;
  created_by: string;
  created_at: Date;
};

export async function fetchAllFirmwares(
  abortSignal: AbortSignal | null = null
): Promise<FirmwareType[]> {
  const res = await apiCall("/api/v1/firmwares", true, { signal: abortSignal });
  return res;
}

export async function fetchAllComponentsFirmwares(
  abortSignal: AbortSignal | null = null
): Promise<FirmwareType[]> {
  const res = await apiCall("/api/v1/firmwares/components", true, {
    signal: abortSignal,
  });
  return res;
}

export type DeviceConfigurationType = {
  action_type: string; // action_type: update_geofence / update_config
  tenant_id: string;
  is_deactivated: boolean;
  version_name: string;
  config_json: any;
};

export async function fetchAllDeviceConfiguration(): Promise<
  DeviceConfigurationType[]
> {
  const res = await apiCall("/api/v1/device-configs", true);
  return res;
}

export async function updateDeviceMetadata(
  metadataBody: MetadataType
): Promise<any> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/devices/metadata`;

  const res = await axios.request({
    method: "POST",
    url: encodeURI(url),
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(metadataBody),
  });

  return res;
}

export async function filterDeviceSearch(
  // Handle showing active and inactive device here
  query: string,
  key: string,
  page: number | string,
  limit: number | string,
  status: string = "active",
  abortSignal: AbortSignal | null = null
): Promise<Device[]> {
  const res = await apiCall(
    encodeURI(
      `/api/v1/devices/search?query=${query}&key=${key}&page=${page}&limit=${limit}&status=${status}`
    ),
    true,
    { signal: abortSignal }
  );
  return res;
}

// --------------------------------------------------------- Device Components APIs -----------------------------------------------

export interface DeviceComponent {
  name: string;
}

export interface IDeviceComponent extends DeviceComponent {
  id: number;
  is_deleted: boolean;
}

export interface IDeviceComponentResult {
  results: IDeviceComponent[];
}

export async function fetchAllDeviceComponents(
  abortSignal: AbortSignal | null = null
): Promise<IDeviceComponentResult> {
  const res = await apiCall("/api/v1/device_components", true, {
    signal: abortSignal,
  });
  return res;
}

export interface CreateDeviceComponentResponse extends IDeviceComponent {
  tenant_id: string;
}

export async function createDeviceComponent(
  component: DeviceComponent
): Promise<AxiosResponse<CreateDeviceComponentResponse>> {
  const tenant = getTenantFromURL();
  const url = "/api/v1/device_components";

  const res = await axios.request({
    url: url,
    method: "POST",
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(component),
  });
  return res;
}

export async function updateDeviceComponent(
  componentId,
  component: DeviceComponent
): Promise<AxiosResponse<{ updated: boolean }>> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/device_components/${encodeURIComponent(componentId)}`;
  const res = await axios.request({
    url: url,

    method: "PUT",
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(component),
  });
  return res;
}

export async function deleteDeviceComponent(
  componentId
): Promise<{ deleted: boolean }> {
  const res = await apiCall(
    `/api/v1/device_components/${encodeURIComponent(componentId)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

// -------------------------------------------------- End of Device Components APIs -----------------------------------------------

// --------------------------------------------------------- Actions APIs ---------------------------------------------------------
export type StatusTypeStruct = {
  Queued?: number;
  Initiated?: number;
  Failed?: number;
  Completed?: number;
  ShellSpawned?: number;
  in_progress?: number;
  Progress?: number;
  "Waiting for user"?: number;
  "Waiting for Bluetooth"?: number;
  Downloaded?: number;
  Received?: number;
  "Extracting payload"?: number;
  "Finalizing update"?: number;
  Installing?: number;
  Installed?: number;
  Downloading?: number;
  PendingApproval?: number;
  Scheduled?: number; // Pending approval
};

export type ActionStatusResponse = {
  actions: ActionStatusType;
  count: number;
};

export type ActionStatusType = {
  action_id: number;
  user_name: string;
  user_email: string;
  type: string;
  created_at: Date;
  statuses: StatusTypeStruct;
  statuses_phased?: StatusTypeStruct;
  params: string;
  payload_type: string;
  schedule?: any;
  release_notes?: string;
};

export type ActionStatusDetail = {
  device_id: number;
  "-serial_metadata"?: { [key: string]: string };
  status: string;
  phase: number;
  progress: number;
  errors: string[];
  updated_at: Date;
};

export async function fetchActionStatusDetails(
  actionId: number,
  status: any,
  pageNumber: number,
  limit: number
): Promise<{ device_actions: ActionStatusDetail[] }> {
  let url = "";
  let partialURL = "";
  if (Array.isArray(status)) {
    status.forEach((status) => {
      partialURL += `status=${status}&`;
    });
    url = `/api/v1/actions/${actionId}/device_actions?${partialURL}page=${
      pageNumber - 1
    }&limit=${limit}`;
  } else
    url = `/api/v1/actions/${actionId}/device_actions?status=${status}&page=${
      pageNumber - 1
    }&limit=${limit}`;
  const res = await apiCall(encodeURI(url), true);
  return res;
}

export async function fetchDeviceActionStatusDetailsWithPhase(
  actionId: number,
  status: any,
  pageNumber: number,
  limit: number,
  phaseIndex: number
): Promise<{ device_actions: ActionStatusDetail[]; count: number }> {
  let url = "";
  let partialURL = "";
  if (Array.isArray(status)) {
    status.forEach((status) => {
      partialURL += `status=${status}&`;
    });
    url = `/api/v1/actions/${actionId}/device_actions?${partialURL}page=${
      pageNumber - 1
    }&limit=${limit}`;
  } else {
    url = `/api/v1/actions/${actionId}/device_actions?status=${status}&page=${
      pageNumber - 1
    }&limit=${limit}`;
  }
  if (phaseIndex >= 0) {
    url = `${url}&phase=${phaseIndex}`;
  }
  const res = await apiCall(encodeURI(url), true);
  return res;
}

export async function fetchAction(actionId: number): Promise<ActionStatusType> {
  const url = `/api/v1/actions/${actionId}`;
  const res = await apiCall(url, true);
  return res;
}

export async function fetchActionFilterOptions(
  page: number,
  filters: Filters
): Promise<ActionFilterOptions> {
  const query = convertToURLParams(filters);
  const url = `/api/v1/actions/filter-options?page=${page}&limit=10000&${query}`; // setting limit to 10000 for now to support searching ids
  const res = await apiCall(url, true);
  return res;
}

export interface ActionLog {
  timestamp: number;
  status: "Completed" | "Queued" | "Initiated" | "Failed" | string;
  progress: number;
  logs: string[];
}

export async function fetchActionLogs(
  actionId: string,
  deviceId: string,
  timestamp: Date,
  limit: number,
  abortSignal: AbortSignal | null = null
): Promise<ActionLog[]> {
  const url = `/api/v1/actions/${actionId}/device_actions/${deviceId}/logs?timestamp=${timestamp.valueOf()}&limit=${limit}`;
  const res = await apiCall(url, true, {
    signal: abortSignal,
  });
  return res;
}

export type DashboardHistoryLogsType = {
  id: number;
  user_email: string;
  dashboard_id: number;
  created_at: string;
  name: string | null;
  role_name: string;
  event: string;
  dashboard_json: {};
};

export type DashboardHistoryType = {
  logs: DashboardHistoryLogsType[];
  count: number;
};

export async function fetchDashboardHistoryLogs(
  dashboardId: number,
  named: boolean = false,
  page: number,
  limit: number
): Promise<DashboardHistoryType> {
  const url = `/api/v1/logs/dashboard/${dashboardId}?named=${named}&page=${page}&limit=${limit}`;
  const res = await apiCall(url, true);
  return res;
}

export async function triggerDeviceActionV1(actionBody: any) {
  return await triggerDeviceActionBase("/api/v1/actions", actionBody);
}

export async function triggerDeviceAction(actionBody: any) {
  return await triggerDeviceActionBase("/api/v2/actions", actionBody);
}

async function triggerDeviceActionBase(url: string, actionBody: any) {
  const tenant = getTenantFromURL();

  const res = await axios.request({
    url: url,
    method: "POST",
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(actionBody),
    validateStatus: (_) => true,
  });
  return res;
}

export type AppendPhaseDataType = {
  name: string;
  trigger_on: {
    timestamp: Date | number;
  };
  info: {
    type: "fixed-list"; // only fixed-list phases can be added
    device_ids: Array<string>;
  };
};

export async function appendPhaseToAction(
  actionId: number,
  phaseData: NewCreateActionPhaseSchema
): Promise<void> {
  const url = `/api/v1/actions/${actionId}/append_phase`;
  await apiCall(url, true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(phaseData),
  });
}

export async function approveAction(actionId: string): Promise<void> {
  const url = `/api/v2/actions/${encodeURIComponent(actionId)}/approve`;
  await apiCall(url, true, {
    method: "PUT",
  });
}

export async function rejectAction(actionId: string): Promise<void> {
  const url = `/api/v2/actions/${encodeURIComponent(actionId)}/reject`;
  await apiCall(url, true, {
    method: "PUT",
  });
}

export async function requestPermissionForAction(actionBody: any) {
  const url = `/api/v2/actions/request`;
  const tenant = getTenantFromURL();

  const res = await axios.request({
    url: url,
    method: "POST",
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(actionBody),
    validateStatus: (_) => true,
  });

  return res;
}

export async function fetchActionParams(actionId: string): Promise<{
  params: string;
}> {
  const url = `/api/v1/actions/${actionId}/params`;
  const res = await apiCall(url, true, {
    method: "GET",
  });
  return res;
}

export async function fetchAllActionStatus(
  pageNumber: number,
  pageLimit: number,
  sortByIncomplete: boolean = false,
  abortSignal: AbortSignal | null = null,
  filters: Filters = {}
): Promise<ActionStatusResponse> {
  const filterQuery = convertToURLParams(filters);
  const url = `/api/v1/actions?page=${pageNumber}&limit=${pageLimit}&incomplete=${sortByIncomplete}&${filterQuery}`;
  const res = await apiCall(url, true, {
    signal: abortSignal,
  });
  return res;
}

export type DeviceActionType = {
  action_id: number;
  created_at: string;
  device_id: string;
  errors: string[];
  params: string;
  phase: number;
  progress: number;
  status: string;
  action_status: "FINISHED" | "IN_PROGRESS" | "QUEUED" | "FAILED";
  type: string;
  updated_at: string;
  user_email: string;
  user_name: string;
  payload_type?: string;
};

export async function fetchAllDeviceActions(
  deviceId: number,
  incomplete: boolean = false,
  activePage: number = 1,
  pageLimit: number = 10,
  sortColumn: string = "action_id",
  sortOrder: "asc" | "desc" = "desc",
  filters: Filters = {},
  abortSignal: AbortSignal | null = null
): Promise<{
  device_actions: DeviceActionType[];
  count: number;
}> {
  const filterQuery = convertToURLParams(filters);
  const url = `/api/v1/devices/${encodeURIComponent(deviceId)}/actions?incomplete=${incomplete}&page=${activePage}&limit=${pageLimit}&sort_column=${sortColumn}&sort_order=${sortOrder}${filterQuery.size > 0 ? `&${filterQuery}` : ""}`;
  const res = await apiCall(url, true, {
    method: "GET",
    signal: abortSignal,
  });
  return res;
}

export async function fetchAllDevicesActionsFiltersOptions(
  deviceId: number,
  page: number,
  incomplete: boolean = false,
  filters: Filters,
  abortSignal: AbortSignal | null = null
): Promise<ActionFilterOptions> {
  const query = convertToURLParams(filters);
  const url = `/api/v1/devices/${encodeURIComponent(deviceId)}/actions/filter-options?incomplete=${incomplete}&page=${page}&limit=15000${query.size > 0 ? `&${query}` : ""}`; // setting limit to 15000 for now to support searching ids
  const res = await apiCall(url, true, { signal: abortSignal });
  return res;
}

export interface CancelOrRetryActionObj {
  device_ids?: number[];
  all?: boolean;
  phase_idx?: number;
}

export async function cancelActions(
  actionId: string,
  cancelActionObj: CancelOrRetryActionObj
): Promise<any> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/actions/${encodeURIComponent(actionId)}/cancel`;
  const res = await axios.request({
    method: "POST",
    url: encodeURI(url),
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(cancelActionObj),
  });

  return res;
}

export async function retryActions(
  actionId: string,
  retryActionObj: CancelOrRetryActionObj
): Promise<any> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/actions/${encodeURIComponent(actionId)}/retry`;
  const res = await axios.request({
    method: "POST",
    url: encodeURI(url),
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(retryActionObj),
  });

  return res;
}

export async function markAllDeviceActionAsCompleted(
  deviceId: number
): Promise<any> {
  // PUT: /devices/:id/actions/mark_as_completed
  const url = `/api/v1/devices/${encodeURIComponent(
    deviceId
  )}/actions/mark_as_completed`;
  const res = await apiCall(url, true, {
    method: "PUT",
  });
  return res;
}

export async function completeAllActionsForDevices(filter: Filters) {
  const url = `/api/v1/actions/mark_as_completed`;
  const res = await apiCall(url, true, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ metadata: filter }),
  });

  return res;
}

export interface MarkActionAsCompletedBodyType {
  metadata?: Filters;
  all?: boolean;
  phase_idx?: number;
}

export async function markActionAsCompleted(
  actionId: string,
  markActionAsCompletedBody: AtLeastOnePropRequired<MarkActionAsCompletedBodyType>
): Promise<any> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/actions/${encodeURIComponent(
    actionId
  )}/mark_as_completed`;
  const res = await axios.request({
    method: "PUT",
    url: encodeURI(url),
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(markActionAsCompletedBody),
  });

  return res;
}

export async function createFirmware(
  firmwareBody: FirmwareType
): Promise<FirmwareType> {
  const res = await apiCall(`/api/v1/firmwares`, true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(firmwareBody),
  });
  return res;
}

export async function uploadFile(
  url: string,
  body: FormData,
  onUploadProgress: Function
): Promise<any> {
  const tenant = getTenantFromURL();

  const res = await axios.request({
    method: "POST",
    url: encodeURI(url),
    data: body,
    onUploadProgress: (p) => onUploadProgress(p),
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });

  return res;
}

export async function activateFirmware(
  version: string,
  deactivate: boolean
): Promise<FirmwareType> {
  const res = await apiCall(
    `/api/v1/firmwares/${encodeURIComponent(version)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        is_deactivated: deactivate,
      }),
    }
  );
  return res;
}

export async function activateComponentFirmware(
  component: string,
  firmwareVersion: string,
  deactivate: boolean
): Promise<FirmwareType> {
  const res = await apiCall(
    `/api/v1/firmwares/component/${component}/${encodeURIComponent(firmwareVersion)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        is_deactivated: deactivate,
      }),
    }
  );
  return res;
}

export async function editFirmware(
  version: string,
  uncompressed_size: number
): Promise<FirmwareType> {
  const res = await apiCall(
    `/api/v1/firmwares/${encodeURIComponent(version)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uncompressed_size,
      }),
    }
  );
  return res;
}

export async function addOrUpdateFirmwareDependency(
  component: string,
  firmwareVersion: string,
  dependencies: FirmwareVersionDependency[]
): Promise<{ device_component_name: string; version_number: string }> {
  const res = await apiCall(
    `/api/v1/firmwares/component/${component}/${encodeURIComponent(firmwareVersion)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dependencies,
      }),
    }
  );
  return res;
}

export async function downloadFirmware(version: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/firmwares/${encodeURIComponent(version)}/artifact`,
    true
  );
  return res;
}

export async function downloadComponentFirmware(
  component: string,
  version: string
): Promise<any> {
  const res = await apiCall(
    `/api/v1/firmwares/component/${component}/${encodeURIComponent(version)}/artifact`,
    true
  );
  return res;
}

// --------------------------------- Firmware Bundles APIs ---------------------------------

export type FirmwareVersionDependency = {
  component_name: string;
  min_version: string;
};
export interface FirmwareInBundle {
  component_name: string;
  version_number: string;
  file_name: string;
  is_deactivated: boolean;
  checksum: string;
  content_length: number;
  uncompressed_size: number | null;
  firmware_id: string;
  dependencies: FirmwareVersionDependency[];
}

export interface FirmwareBundle {
  id: string;
  version: string;
  file_name: string;
  firmwares: FirmwareInBundle[];
  upload_status: string;
  content_length: number;
  created_at: Date;
  created_by: string;
  checksum: string;
  uncompressed_size: number;
}

export interface FirmwareBundleResult {
  results: FirmwareBundle[];
}

export async function fetchAllFirmwareBundles(
  abortSignal: AbortSignal | null = null
): Promise<FirmwareBundleResult> {
  const res = await apiCall(`/api/v1/firmware-bundles`, true, {
    signal: abortSignal,
  });
  return res;
}

export async function downloadFirmwareBundleById(
  bundleId: string
): Promise<any> {
  const res = await apiCall(
    `/api/v1/firmware-bundles/${encodeURIComponent(bundleId)}/artifact`,
    true
  );
  return res;
}

export async function createFirmwareBundle(
  firmwares: { component_name: string; version_number: string }[],
  version: string,
  file_name?: string
): Promise<AxiosResponse<{ id: string }>> {
  const url = `/api/v1/firmware-bundles`;
  const tenant = getTenantFromURL();

  const res = await axios.request({
    url,
    method: "POST",
    headers: {
      "X-Bytebeam-Tenant": tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      file_name: file_name ?? "bundle.zip",
      version,
      firmwares,
    }),
  });

  return res;
}

export async function updateFirmwareBundleById(
  bundleId: string,
  bundleBody: {
    uncompressed_size: number;
    upload_status: string;
    content_length: number;
  }
): Promise<any> {
  const res = await apiCall(`/api/v1/firmware-bundles/id/${bundleId}`, true, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bundleBody),
  });

  return res;
}

export async function deleteFirmwareBundleById(bundleId: string): Promise<any> {
  const res = await apiCall(`/api/v1/firmware-bundles/id/${bundleId}`, true, {
    method: "DELETE",
  });

  return res;
}
// --------------------------------- End of Firmware Bundles APIs ---------------------------------

export function createDeviceConfiguration(
  version_name: string,
  config_json: any,
  action_type: string
): Promise<any> {
  return apiCall(`/api/v1/device-configs`, true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      version_name,
      config_json,
      action_type,
    }),
  });
}

export async function editDeviceConfiguration(
  version_name: string,
  config_json: any
): Promise<any> {
  const res = await apiCall(
    `/api/v1/device-configs/${encodeURIComponent(version_name)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        config_json,
      }),
    }
  );
  return res;
}

export async function fetchDeviceConfigurationDetails(
  name: string
): Promise<DeviceConfigurationType> {
  const res = await apiCall(
    `/api/v1/device-configs/${encodeURIComponent(name)}`,
    true,
    {
      method: "GET",
    }
  );
  return res;
}

export async function deactivateDeviceConfig(
  version: string
): Promise<DeviceConfigurationType> {
  const res = await apiCall(
    `/api/v1/device-configs/${encodeURIComponent(version)}/deactivate`,
    true,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res;
}

export async function fetchProgressHistogramStats(
  actionID: string
): Promise<ProgressHistogramStats> {
  const res = await apiCall(
    `/api/v1/actions/${encodeURIComponent(actionID)}/progress_histogram`,
    true,
    {
      method: "GET",
    }
  );

  return res;
}

export async function fetchActionErrorStats(
  action_id: number,
  abortSignal: AbortSignal | null = null
): Promise<Record<string, number>> {
  const res = await apiCall(`/api/v1/actions/${action_id}/error-stats`, true, {
    method: "GET",
    signal: abortSignal,
  });
  return res;
}

export async function fetchDeviceActionLatencyStates(
  action_id: number,
  abortSignal: AbortSignal | null = null
) {
  const res = await apiCall(
    `/api/v1/actions/${action_id}/device-latencies/states`,
    true,
    {
      method: "GET",
      signal: abortSignal,
    }
  );

  return res;
}

export async function fetchDeviceActionLatency(
  action_id: number,
  action_status: string,
  abortSignal: AbortSignal | null = null
) {
  const res = await apiCall(
    `/api/v1/actions/${action_id}/device-latencies`,
    true,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
      body: JSON.stringify({
        state: action_status,
        buckets_count: 25,
      }),
    }
  );
  return res;
}

export type PendingDeviceActionsResponse = Record<
  string,
  Array<{ action_id: number; status: string; type: string }>
>;

export async function getPendingActionsOnDevices(
  filter: Filters
): Promise<PendingDeviceActionsResponse> {
  const res = await apiCall(`/api/v1/pending-actions`, true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filter),
  });

  return res;
}

// --------------------------------------------------------- End of Actions APIs ---------------------------------------------------------

// --------------------------------------------------------- Start of Dashboard APIs ---------------------------------------------------------
export async function fetchDashboardShareOptions(): Promise<any> {
  const url = `/api/v1/dashboard-share-options`;
  const res = await apiCall(url, true);
  return res;
}

export async function editDashboard(
  dashboardId: number,
  dashboardBody: any
): Promise<any> {
  const res = await apiCall(`/api/v1/dashboards/${dashboardId}`, true, {
    method: "POST",
    body: JSON.stringify(dashboardBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
}

export async function updateDashboardHistoryAndRollback(
  dashboardId: number,
  versionId: number,
  versionName: string,
  eventType: "version_edit" | "rollback"
): Promise<any> {
  const res = await apiCall(
    encodeURI(`/api/v1/dashboards/${dashboardId}`),
    true,
    {
      method: "POST",
      body: JSON.stringify({
        id: versionId,
        name: versionName,
        event: eventType,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res;
}

export async function deleteDashboard(dashboardId: number): Promise<void> {
  await apiCall(`/api/v1/dashboards/${dashboardId}`, true, {
    method: "DELETE",
  });
}

export type FetchParams = {
  groupBys: string[];
  filterBys: { [key: string]: string[] };
  timeRange: TimeRange;
  fetchAll: boolean;
};

export async function fetchPanelData(
  dashboard_id: number | string,
  panel_id: string,
  metas: PanelMetaData[],
  { groupBys, filterBys, timeRange, fetchAll }: FetchParams,
  abortSignal: AbortSignal | null = null,
  dashboardType: DashboardType | null = null
) {
  const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
  const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());

  const aggregationInterval = Math.max(
    Math.round((endTime - startTime) / 200),
    1
  );

  // For filter query, converting UI object to HoneySql query
  const queryMetas = metas.map((meta) => {
    if (meta.query && meta.query[0] !== "and" && meta.query.length > 0) {
      meta.query = objectToQuery(meta.query);
    } else if (meta.query?.length === 0) {
      delete meta["query"];
    }
    return meta;
  });

  // Doing dashboardType check here to customize the linechart metas to add groupBys
  const newMetas = queryMetas.map((meta) => {
    if (
      dashboardType &&
      dashboardType === "fleet" &&
      meta.type === "line_chart"
    ) {
      meta.timeseries = meta.timeseries.map((ts) => {
        const groupBysSet = new Set(["id", ...(ts.groupBys ?? [])]);
        ts.groupBys = Array.from(groupBysSet);
        return ts;
      });
    }
    return meta;
  });

  // filterBys Cleanup due to state param begin appended from url
  if (filterBys.state) {
    delete filterBys.state;
  }

  const requestBody = {
    dashboard_id: String(dashboard_id),
    panel_id: String(panel_id),
    startTime,
    endTime,
    aggregationInterval,
    filterBys: filterBys,
    groupBys: groupBys,
    panels: newMetas,
    fetchAll: fetchAll,
  };

  try {
    const res = await apiCall(`/api/v1/panel-data`, true, {
      method: "post",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });
    return res;
  } catch (error) {
    console.error("Error occurred in fetchPanelData: ", error);
    throw error;
  }
}

export async function fetchPanelDataFixedTimeRange(
  dashboard_id: number | string,
  panel_id: string,
  metas: PanelMetaData[],
  { groupBys, filterBys, timeRange, fetchAll }: FetchParams,
  startTimestamp,
  endTimestamp,
  abortSignal: AbortSignal | null = null
) {
  const startTime =
    startTimestamp ?? Math.round(timeRange.getStartTime().toDate().valueOf());
  const endTime =
    endTimestamp ?? Math.round(timeRange.getEndTime().toDate().valueOf());

  const aggregationInterval = Math.max(
    Math.round((endTime - startTime) / 200),
    1
  );

  const newMetas = metas.map((meta) => {
    if (meta.query && meta.query[0] !== "and" && meta.query.length > 0) {
      meta.query = objectToQuery(meta.query);
    } else if (meta.query?.length === 0) {
      delete meta["query"];
    }
    return meta;
  });

  // filterBys Cleanup due to state param begin appended from url
  if (filterBys.state) {
    delete filterBys.state;
  }

  const requestBody = {
    dashboard_id: String(dashboard_id),
    panel_id: String(panel_id),
    startTime,
    endTime,
    aggregationInterval,
    filterBys: filterBys,
    groupBys: groupBys,
    panels: newMetas,
    fetchAll: fetchAll,
  };

  try {
    const res = await apiCall(`/api/v1/panel-data`, true, {
      method: "post",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });
    return res;
  } catch (error) {
    console.error("Error occurred in fetchPanelData: ", error);
    throw error;
  }
}

export async function fetchMicelioStatsPanelDetails(
  meta: PanelMetaData,
  { groupBys, filterBys, timeRange, fetchAll }: FetchParams
) {
  const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
  const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());

  const aggregationInterval = Math.max(
    Math.round((endTime - startTime) / 200),
    1
  );

  const requestBody = {
    startTime,
    endTime,
    aggregationInterval,
    filterBys: filterBys,
    groupBys: groupBys,
    panelMeta: meta,
    fetchAll: fetchAll,
  };

  const response = await fetch(`/api/v1/micelio-stats-panel-details`, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const details = await response.json();

  return details;
}

export async function fetchStreamTimeSeries(requestBody) {
  try {
    const res = await apiCall(`/api/v1/panel-data`, true, {
      method: "post",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    console.error("Error occurred in fetchStreamTimeSeries: ", error);
  }
}
// --------------------------------------------------------- End of Dashboard APIs ---------------------------------------------------------

export async function fetchAllDevicesWithCountV2(
  pageNo: number | string = 1,
  limit: number | string = 10,
  // Handle showing active and inactive device here, status can be active, inactive or all
  status: string = "active",
  abortSignal: AbortSignal | null = null
): Promise<SearchDeviceResponse> {
  const res = await apiCall(
    encodeURI(`/api/v2/devices?page=${pageNo}&limit=${limit}&status=${status}`),
    true,
    { signal: abortSignal }
  );
  return res;
}

export async function fetchAllDevices(
  pageNo: number | string = 1,
  limit: number | string = 10,
  // Handle showing active and inactive device here, status can be active, inactive or all
  status: string = "active",
  abortSignal: AbortSignal | null = null
): Promise<Device[]> {
  const res = await apiCall(
    encodeURI(`/api/v1/devices?page=${pageNo}&limit=${limit}&status=${status}`),
    true,
    { signal: abortSignal }
  );
  return res;
}

export async function fetchDevicesCount(
  key: string,
  query: string = "",
  status: string = "active",
  abortSignal: AbortSignal | null = null
): Promise<number> {
  // Handle showing active and inactive device here
  let apiEndpoint =
    query !== ""
      ? `/api/v1/devices/count?key=${key}&query=${query}&status=${status}`
      : `/api/v1/devices/count?status=${status}`;
  const res = await apiCall(encodeURI(apiEndpoint), true, {
    signal: abortSignal,
  });
  let data = await res.count;
  const deviceCount = parseInt(data);
  return deviceCount;
}

export async function createUser(user: User): Promise<{ result: IUser }> {
  const res = await apiCall("/api/v1/users", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return res;
}

export async function createApiKey(apiKey: ApiKey) {
  const res = await apiCall("/api/v1/apikeys", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiKey),
  });
  return res;
}

export async function editApiKey(
  apiKey: string,
  apiKeyNameObj: Partial<ApiKey>
) {
  const res = await apiCall(
    `/api/v1/apikeys/${encodeURIComponent(apiKey)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiKeyNameObj),
    }
  );
  return res;
}

export async function editUser(
  userId: string,
  user: User
): Promise<{ result: IUser }> {
  const res = await apiCall(
    `/api/v1/users/${encodeURIComponent(userId)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }
  );
  return res;
}

export async function createRole(role: {
  name: string;
  permissions: Permission;
}): Promise<{ result: { id: number; name: string } }> {
  const res = await apiCall("/api/v1/roles", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(role),
  });
  return res;
}

export async function editRole(
  roleId: number,
  role: { name: string; permissions: Permission }
): Promise<{ result: { id: number; name: string } }> {
  const res = await apiCall(`/api/v1/roles/${roleId}`, true, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(role),
  });
  return res;
}

export async function createMetadataKey(key: {
  key: string;
}): Promise<{ result: string }> {
  const res = await apiCall("/api/v1/devices/metadata-keys", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(key),
  });
  return res;
}

export async function editMetadataKey(
  oldKey: string,
  newkey: { key: string }
): Promise<{ result: string }> {
  const res = await apiCall(
    `/api/v1/devices/metadata-keys/${encodeURIComponent(oldKey)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newkey),
    }
  );
  return res;
}

export async function createActionType(
  actionType: ActionType
): Promise<{ result: ActionType }> {
  const res = await apiCall("/api/v1/action-types", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(actionType),
  });
  return res;
}

export async function editActionType(
  action: string,
  actionType: ActionType
): Promise<{ result: ActionType }> {
  const request: any = { ...actionType };
  delete request.type;
  const res = await apiCall(
    `/api/v1/action-types/${encodeURIComponent(action)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    }
  );
  return res;
}

export async function createStreamField(fieldBody: {
  streamName: string;
  fieldName: string;
  fieldType: string;
  fieldUnit: string | null;
}): Promise<any> {
  const res = await apiCall("/api/v1/streams/fields", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fieldBody),
  });
  return res;
}

export async function updateStreamField(fieldBody: {
  streamName: string;
  fieldName: string;
  fieldType: string;
  fieldUnit: string | null;
}): Promise<any> {
  const res = await apiCall("/api/v1/streams/fields/update", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fieldBody),
  });
  return res;
}

export async function deleteStreamField(
  tableName: string,
  columnName: string
): Promise<any> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(
      tableName
    )}/fields/${encodeURIComponent(columnName)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

export const streamProtobuff = "/api/v1/streams/protobuff";

export async function createComputedStream(streamBody: {
  stream: { streamName: string; fields: { any } };
}): Promise<any> {
  const res = await apiCall("/api/v1/streams/computed_streams", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(streamBody),
  });
  return res;
}

export async function createNonComputedStream(stream: {
  streamName: string;
  fields: { any };
}): Promise<any> {
  const res = await apiCall("/api/v1/streams", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stream),
  });
  return res;
}

export async function deleteStream(tableName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(tableName)}`,
    true,
    {
      method: "DELETE",
    }
  );
  return res;
}

export async function createDevice(
  metadata: any,
  dedup: boolean
): Promise<
  AxiosResponse<{
    cert: string;
    id: number;
    priv_key: string;
    pub_key: string;
    device_id: string;
  }>
> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/devices/provision?dedup=${encodeURIComponent(dedup)}`;

  try {
    const res: any = await axios.request({
      method: "POST",
      url: encodeURI(url),
      headers: {
        "Content-Type": "application/json",
        "X-Bytebeam-Tenant": tenant,
      },
      data: JSON.stringify({ metadata }),
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchAllDashboards(
  fullData: boolean = false
): Promise<DashboardAPIResponse[]> {
  const res = await apiCall(`/api/v1/dashboards?full-data=${fullData}`, true);
  return res;
}

export async function createDashboard(dbBody: any): Promise<any> {
  const res = await apiCall("/api/v1/dashboards", true, {
    method: "POST",
    body: JSON.stringify(dbBody),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export function getEndUserAuth(): Promise<Auth> {
  // TODO this won't work in single tenant case!
  // const tenant = localStorage.getItem("tenant");
  const tenant = getTenantFromURL();

  return apiCall(`/api/v1/tenants/${tenant}/auth`, true);
}

export function putEndUserAuth(auth: Auth): Promise<any> {
  // TODO this won't work in single tenant case!
  // const tenant = localStorage.getItem("tenant");
  const tenant = getTenantFromURL();

  return apiCall(`/api/v1/tenants/${tenant}/auth`, true, {
    method: "PUT",
    body: JSON.stringify(auth),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function downloadCertificates(
  deviceId: number
): Promise<Device[]> {
  const res = await apiCall(`/api/v1/devices/${deviceId}/cert`, true);
  return res;
}

export async function changeDeviceStatus(
  deviceId: number,
  state: string
): Promise<Device[]> {
  const res = await apiCall(`/api/v1/devices/${deviceId}`, true, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status: state, //"active|inactive"
    }),
  });
  return res;
}

export async function downloadUpdateMetadataTemplate(): Promise<any> {
  const res = await apiCall("/api/v1/devices/metadata-bulk-template", true);
  return res;
}

export async function updateTenantSettings(settingBody: {
  settings: TenantSettings;
}): Promise<TenantSettings> {
  const res = await apiCall("/api/v1/me/tenant/settings", true, {
    method: "PUT",
    body: JSON.stringify(settingBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
}

export async function updateUserSettings(settingBody: {
  settings: UserSettings;
}): Promise<UserSettings> {
  const res = await apiCall("/api/v1/me/settings", true, {
    method: "PUT",
    body: JSON.stringify(settingBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
}

// --------------------------------------------------------- Start of Alert and Session APIs ---------------------------------------------------------
export async function fetchAlertTypes() {
  const res = await apiCall("/api/v1/alert-types", true);
  return res;
}

export async function fetchSessionTypes(): Promise<SessionType[]> {
  const res = await apiCall("/api/v1/session-types", true);
  return res;
}

export async function fetchAlertRules(): Promise<AlertRule[]> {
  const res = await apiCall("/api/v1/alert-rules", true);
  return res;
}

export async function fetchAlertGroups(): Promise<AlertGroups> {
  const res = await apiCall("/api/v1/alert-groups", true);
  return res.alert_groups;
}

export async function createSessionType(
  sessionType: SessionType
): Promise<any> {
  const res = await apiCall("/api/v1/session-types", true, {
    method: "POST",
    body: JSON.stringify(sessionType),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function createAlertRule(alertRule: AlertRule): Promise<any> {
  const res = await apiCall("/api/v1/alert-rules", true, {
    method: "POST",
    body: JSON.stringify(alertRule),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function createAlertNotificationRule(
  notificationRule: AlertNotificationRule
): Promise<any> {
  const alertRuleId = notificationRule.alert_rule_id;
  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId
  )}/alert-notification-rules`;

  const body = {
    channel_type: notificationRule.channel_type,
    channel_parameters: notificationRule.channel_parameters,
    interval_seconds: notificationRule.interval_seconds,
    timezone: notificationRule.timezone ?? "UTC",
    activation_template: notificationRule.activation_template,
    deactivation_template: notificationRule.deactivation_template,
    notify_on_deactivation: notificationRule.notify_on_deactivation,
  };

  const res = await apiCall(url, true, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function testAlertNotificationRule(
  notificationRule: AlertNotificationRule
): Promise<any> {
  const alertRuleId = notificationRule.alert_rule_id;
  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId
  )}/test-alert-notification-rule`;

  const body = {
    channel_type: notificationRule.channel_type,
    channel_parameters: notificationRule.channel_parameters,
    interval_seconds: notificationRule.interval_seconds,
    timezone: notificationRule.timezone ?? "UTC",
    activation_template: notificationRule.activation_template,
    deactivation_template: notificationRule.deactivation_template,
    notify_on_deactivation: notificationRule.notify_on_deactivation,
  };

  const res = await apiCall(url, true, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function fetchTimezones(): Promise<string[]> {
  const url = `/api/v1/timezones`;

  const res: { timezones: string[] } = await apiCall(url, true, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.timezones;
}

export async function fetchAlertNotificationRules(
  notificationRule: AlertNotificationRule,
  abortSignal: AbortSignal | null = null
): Promise<AlertNotificationRule> {
  const alertRuleId = notificationRule.alert_rule_id;
  const notificationRuleId = notificationRule.id;

  if (!alertRuleId || !notificationRuleId) {
    console.error(
      "updateAlertNotificationRule: alertRuleId or notificationRuleId is null: ",
      notificationRule
    );
    throw new Error("alertRuleId or notificationRuleId is null");
  }

  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId
  )}/alert-notification-rules/${encodeURIComponent(notificationRuleId)}`;

  const res = await apiCall(url, true, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: abortSignal,
  });

  return res;
}

export async function updateAlertNotificationRule(
  notificationRule: AlertNotificationRule
): Promise<any> {
  const alertRuleId = notificationRule.alert_rule_id;
  const notificationRuleId = notificationRule.id;

  if (!alertRuleId || !notificationRuleId) {
    console.error(
      "updateAlertNotificationRule: alertRuleId or notificationRuleId is null: ",
      notificationRule
    );
    return;
  }

  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId
  )}/alert-notification-rules/${encodeURIComponent(notificationRuleId)}`;

  const body = {
    channel_type: notificationRule.channel_type,
    channel_parameters: notificationRule.channel_parameters,
    interval_seconds: notificationRule.interval_seconds,
    timezone: notificationRule.timezone,
    activation_template: notificationRule.activation_template,
    deactivation_template: notificationRule.deactivation_template,
    notify_on_deactivation: notificationRule.notify_on_deactivation,
  };

  const res = await apiCall(url, true, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function deleteAlertNotificationRule(
  notificationRule: AlertNotificationRule
): Promise<any> {
  const alertRuleId = notificationRule.alert_rule_id;
  const notificationRuleId = notificationRule.id;

  if (!alertRuleId || !notificationRuleId) {
    console.error(
      "deleteAlertNotificationRule: alertRuleId or notificationRuleId is null"
    );
    return;
  }

  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId
  )}/alert-notification-rules/${encodeURIComponent(notificationRuleId)}`;

  const res = await apiCall(url, true, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function startAlertRule(alertRuleId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}/start`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

export async function stopAlertRule(alertRuleId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}/stop`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

export async function startSessionType(sessionTypeName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/session-types/${encodeURIComponent(sessionTypeName)}/start`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

export async function stopSessionType(sessionTypeName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/session-types/${encodeURIComponent(sessionTypeName)}/stop`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

export async function deleteSessionType(sessionTypeName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/session-types/${encodeURIComponent(sessionTypeName)}`,
    true,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

export async function deleteAlertRule(alertRuleId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}`,
    true,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

export async function updateAlertRule(
  alertRuleId: string,
  alertRule: AlertRule
): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}`,
    true,
    {
      method: "PUT",
      body: JSON.stringify(alertRule),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res;
}
// --------------------------------------------------------- End of Alert and Session APIs ---------------------------------------------------------

// --------------------------------------------------------- Start of DBC APIs ---------------------------------------------------------

export async function createDBCParser(
  body: FormData
): Promise<AxiosResponse<DBCResponse>> {
  const url = "/api/v1/dbcs";
  const tenant = getTenantFromURL();

  const res = await axios.request({
    url: encodeURI(url),
    method: "POST",
    data: body,
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });

  return res;
}

export async function fetchAllDBCs(
  abortSignal: AbortSignal | null = null
): Promise<AllDBCResponse> {
  const res = await apiCall("/api/v1/dbcs", true, {
    method: "GET",
    signal: abortSignal,
  });
  return res;
}

export async function fetchDBC(DbcID: string): Promise<SingleDBCResponse> {
  const res = await apiCall(`/api/v1/dbcs/${encodeURIComponent(DbcID)}`, true, {
    method: "GET",
  });
  return res;
}

export async function fetchDBCFile(DbcID: string): Promise<DBCFileResponse> {
  const res = await apiCall(
    `/api/v1/dbcs/${encodeURIComponent(DbcID)}/dbc`,
    true,
    {
      method: "GET",
    }
  );
  return res;
}

export async function fetchParsedDBC(
  DbcID: string
): Promise<ParsedDBCResponse> {
  const res = await apiCall(
    `/api/v1/dbcs/${encodeURIComponent(DbcID)}/json`,
    true,
    {
      method: "GET",
    }
  );
  return res;
}

export type DBCSignalWithCAN = {
  can_id: number;
  byte_order: string;
  choices: Record<string, any> | null;
  database_column: string;
  is_signed: boolean;
  is_float: boolean;
  length: number;
  name: string;
  offset: number;
  scale: number;
  start: number;
};

export type DBCDiffResponse = {
  deleted_signals: DBCSignalWithCAN[];
  added_signals: DBCSignalWithCAN[];
  updated_signals: { from: DBCSignalWithCAN; to: DBCSignalWithCAN }[];
};

export async function uploadNewDBCForDiff(
  dbcId: string,
  body: FormData,
  onUploadProgress: Function
): Promise<AxiosResponse<DBCDiffResponse>> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/dbcs/${encodeURIComponent(dbcId)}/diff`;

  const res = await axios.request({
    url,
    method: "POST",
    data: body,
    onUploadProgress: (p) => onUploadProgress(p),
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });

  return res;
}

export type UpdatedSignal = {
  from: DBCSignalWithCAN;
  to: DBCSignalWithCAN;
};

export async function updateDBCParsing(
  dbcId: string,
  body: FormData
): Promise<any> {
  const tenant = getTenantFromURL();
  const url = `/api/v1/dbcs/${encodeURIComponent(dbcId)}/update`;

  const res = await axios.request({
    url,
    method: "PUT",
    data: body,
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });

  return res as AxiosResponse<DBCDiffResponse>;
}

export async function updateDBCSettings(
  DbcID: string,
  body: EditDBCParserInput
): Promise<any> {
  const res = await apiCall(`/api/v1/dbcs/${encodeURIComponent(DbcID)}`, true, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
}

export async function deleteDBC(DbcId: string): Promise<DeleteDBCResponse> {
  const res = await apiCall(`/api/v1/dbcs/${encodeURIComponent(DbcId)}`, true, {
    method: "DELETE",
  });

  return res;
}

export async function startDBCParser(
  DbcID: string
): Promise<SingleDBCResponse> {
  const res = await apiCall(
    `/api/v1/dbcs/${encodeURIComponent(DbcID)}/start`,
    true,
    {
      method: "PUT",
    }
  );
  return res;
}

export async function stopDBCParser(DbcID: string): Promise<SingleDBCResponse> {
  const res = await apiCall(
    `/api/v1/dbcs/${encodeURIComponent(DbcID)}/stop`,
    true,
    {
      method: "PUT",
    }
  );
  return res;
}

// --------------------------------------------------------- End of DBC APIs ---------------------------------------------------------
