import React, { useState, useRef, useEffect, memo } from "react";
import { Table, Icon, Popup, Accordion, Grid } from "semantic-ui-react";
import ConfirmationModal from "../../common/ConfirmationModal";
import ColumnType from "./ColumnType";
import CreateStreamModal from "./CreateStreamModal";
import { filter, mapIf, updateColumns, mapColumnIf } from "./functional";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { DisplayIf, filterStreamTableInfo, isNumericType } from "../../util";
import {
  createStreamField,
  deleteStream,
  deleteStreamField,
  updateStreamField,
  fetchAllStreamsWithDetails,
  fetchAlertRules,
  StreamFieldDetails,
  fetchAllDBCs,
} from "../../../../BytebeamClient";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";
import ColumnUnit from "./ColumnUnit";
import { useUser } from "../../../../context/User.context";
import { CardContainer } from "../../../common/ActionsUtils";
import { StyledHeader } from "../../Actions/ActionsV3/SelectableItem";
import { Virtuoso } from "react-virtuoso";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { CodeQueryText } from "../../DeviceManagement/Devices/ActionModals/DownloadFilteredMetadataModal";
import { StyledInput } from "../../../common/StyledComponents/SearchInput";
import AddOrEditColumn from "./AddOrEditColumn";
import * as uuid from "uuid";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";

export const formatNames = (names: string[]) =>
  names.length <= 2 ? names.join(", ") : `${names.slice(0, 2).join(", ")}...`;

const sortColumns = (columns) => {
  return columns.sort((a, b) => {
    if (a.required !== b.required) return a.required ? -1 : 1;
    return a.name.localeCompare(b.name);
  });
};

type ColumnsType = StreamFieldDetails & {
  name: string;
  newType: string;
  newUnit: string;
  status: string;
};

type TableType = {
  tableName: string;
  cols: ColumnsType[];
  slatedForDeletion: boolean;
  status: string;
  error: string;
};

type DeleteTableModalProps = {
  isStreamDeleting: Record<string, boolean>;
  tableName: string;
  onConfirm: () => Promise<void>;
  alertRulesStreamName: Map<string, string[]>;
  dbcParserStreamName: Map<string, string[]>;
};

const DeleteTableModal = (props: DeleteTableModalProps) => {
  const {
    isStreamDeleting,
    tableName,
    onConfirm,
    alertRulesStreamName,
    dbcParserStreamName,
  } = props;

  const isDBCParserStream = dbcParserStreamName.has(tableName);
  const isAlertRulesStream = alertRulesStreamName.has(tableName);
  const streamDbcParserNames = dbcParserStreamName.get(tableName) ?? [];
  const streamAlertRuleNames = alertRulesStreamName.get(tableName) ?? [];

  if (isStreamDeleting?.[tableName]) {
    return (
      <LoadingAnimation
        loaderSize="15px"
        marginTopText="0px"
        loaderBorderSize="2px"
      />
    );
  }

  let message = "Delete Stream";
  // used filter to remove "false" value from displaying
  if (isDBCParserStream || isAlertRulesStream) {
    message = [
      isDBCParserStream &&
        `Cannot delete stream with DBC parsers: ${formatNames(streamDbcParserNames)}`,
      isAlertRulesStream &&
        `Cannot delete stream with Alert Rules: ${formatNames(streamAlertRuleNames)}`,
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <Popup
        id="delete-stream-alert-rule-popup"
        content={message}
        inverted
        position="top center"
        trigger={
          <Icon
            link
            name="trash"
            disabled={isDBCParserStream || isAlertRulesStream}
          />
        }
      />
    );
  }

  return (
    <Popup
      content={message}
      inverted
      position="top center"
      trigger={
        <div>
          <ConfirmationModal
            prefixContent="Delete stream"
            expectedText={tableName}
            onConfirm={onConfirm}
            trigger={<Icon link name="trash" />}
            message={
              <ConfirmationModalMessage name={tableName} type="Stream Table" />
            }
          />
        </div>
      }
    />
  );
};

type DeleteColModalProps = {
  tableName: string;
  columnName: string;
  onConfirm: () => void;
  dbcParserStreamName: Map<string, string[]>;
  alertRulesStreamName: Map<string, string[]>;
};

const DeleteColModal = ({
  tableName,
  columnName,
  onConfirm,
  dbcParserStreamName,
  alertRulesStreamName,
}: DeleteColModalProps) => {
  const isDBCParserStream = dbcParserStreamName.has(tableName);
  const streamDbcParserNames = dbcParserStreamName.get(tableName) ?? [];
  const isAlertRulesStream = alertRulesStreamName.has(tableName);
  const streamAlertRuleNames = alertRulesStreamName.get(tableName) ?? [];

  if (isDBCParserStream || isAlertRulesStream) {
    const message = [
      isDBCParserStream &&
        `Cannot delete column in stream used by DBC Parser: ${formatNames(streamDbcParserNames)}`,
      isAlertRulesStream &&
        `Cannot delete column in stream used by Alert Rules: ${formatNames(streamAlertRuleNames)}`,
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <Popup
        id="delete-stream-alert-rule-popup"
        content={message}
        inverted
        position="top center"
        trigger={
          <Icon
            link
            name="trash"
            disabled={isDBCParserStream || isAlertRulesStream}
          />
        }
      />
    );
  }

  return (
    <ConfirmationModal
      prefixContent="Delete column"
      expectedText={columnName}
      onConfirm={onConfirm}
      trigger={<Icon link name="trash" />}
      message={<ConfirmationModalMessage name={columnName} type="Column" />}
    />
  );
};

const highlightText = (text, query) => {
  if (!query) return text;

  const regex = new RegExp(`(${query})`, "gi");
  return text.split(regex).map((part) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <CodeQueryText key={part}>
        <span className="codeQueryText" style={{ width: "fit-content" }}>
          {part}
        </span>
      </CodeQueryText>
    ) : (
      part
    )
  );
};

type StreamRowProps = {
  readonly stream: TableType;
  readonly activeAccordion: number;
  readonly index: number;
  readonly alertRulesStreamName: Map<string, string[]>;
  readonly dbcParserStreamName: Map<string, string[]>;
  readonly handleAccordionClick: (index: number) => void;
  readonly searchQuery: string;
  readonly tables: TableType[];
  readonly setTables: (tables: TableType[]) => void;
};

const StreamRow = memo(
  ({
    stream,
    activeAccordion,
    index,
    alertRulesStreamName,
    dbcParserStreamName,
    searchQuery,
    handleAccordionClick,
    tables,
    setTables,
  }: StreamRowProps) => {
    const { user, getCurrentUser } = useUser();
    const permissions = user?.role?.permissions ?? {};

    const isActive = activeAccordion === index;
    const streamError = stream.error;

    const isDBCParserStream = dbcParserStreamName.has(stream.tableName);
    const streamDbcParserNames =
      dbcParserStreamName.get(stream.tableName) ?? [];

    const columnNameSet = new Set([
      "id",
      ...stream.cols.map((col) =>
        col.name.toLowerCase().replace(/\s+/g, "_").trim()
      ),
    ]);

    const [isStreamDeleting, setIsStreamDeleting] = useState({});
    const [isAddOrEditColumnModalOpen, setIsAddOrEditColumnModalOpen] =
      useState<boolean>(false);
    const [modalType, setModalType] = useState<"add" | "edit">("add");
    const [selectedColumn, setSelectedColumn] = useState<{
      name: string;
      type: string;
      unit: string | null;
    } | null>(null);

    const tablesRef = useRef<
      { tableName: string; cols: StreamFieldDetails[] }[]
    >([{ tableName: "", cols: [] }]);
    tablesRef.current = tables;

    // Normally to add a new table, you would:
    // setTable([newTable, ...tables])
    // But if it is done in a callback, you will only
    // have access to old tables. To avoid this nuance, use
    // updateTables(tables => [newTable, ...tables]) instead.
    const updateTables = (transition) => {
      setTables(transition(tablesRef.current));
    };

    const handleDeleteTable = async (tableName) => {
      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          (table) => ({ ...table, slatedForDeletion: true })
        )
      );

      setIsStreamDeleting((prev) => ({ ...prev, [tableName]: true }));
      try {
        await deleteStream(tableName);
        updateTables(filter((row) => row.tableName !== tableName));
        getCurrentUser();
        beamtoast.success(`${tableName} stream deleted successfully`);
      } catch (error) {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            (table) => ({
              ...table,
              slatedForDeletion: false,
              status: "error",
              error: (error as Error)?.message,
            })
          )
        );
        beamtoast.error(`Error deleting stream ${tableName}`);
      } finally {
        setIsStreamDeleting((prev) => ({ ...prev, [tableName]: false }));
      }
    };

    const handleAddNewColumn = async (
      tableName: string,
      columnName: string,
      columnType: string,
      columnUnit: string | null
    ) => {
      const requestId = uuid.v4();
      columnName = columnName.replace(/\s+/g, "_").trim();
      columnUnit = columnUnit === "" ? null : columnUnit;

      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          updateColumns((cols) => [
            ...cols,
            {
              requestId,
              name: columnName,
              type: columnType,
              unit: columnUnit,
              status: "initiated",
            },
          ])
        )
      );

      try {
        await createStreamField({
          streamName: tableName,
          fieldName: columnName,
          fieldType: columnType,
          fieldUnit: columnUnit,
        });

        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.requestId === requestId,
              (col) => ({ ...col, status: "ready" })
            )
          )
        );

        await getCurrentUser();

        beamtoast.success(
          `New column ${columnName} is created in ${tableName} stream successfully`
        );
      } catch (e) {
        beamtoast.error(
          `Error creating ${columnName} in ${tableName} stream column`
        );
        console.log(e);
      }
    };

    const handleEditColumn = (
      tableName: string,
      columnName: string,
      newType: string,
      newUnit: string | null
    ) => {
      newUnit = newUnit === "" ? null : newUnit;

      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          mapColumnIf(
            (col) => col.name === columnName,
            (col) => ({
              ...col,
              newType,
              newUnit,
              status: "editing",
            })
          )
        )
      );

      updateStreamField({
        streamName: tableName,
        fieldName: columnName,
        fieldType: newType,
        fieldUnit: newUnit,
      })
        .then(() =>
          updateTables(
            mapIf(
              (table) => table.tableName === tableName,
              mapColumnIf(
                (col) => col.name === columnName,
                (col) => ({
                  ...col,
                  type: newType,
                  unit: newUnit,
                  status: "ready",
                })
              )
            )
          )
        )
        .then(() => {
          getCurrentUser();
          beamtoast.success(`column ${columnName} is updated successfully`);
        })
        .catch((err) => {
          updateTables(
            mapIf(
              (table) => table.tableName === tableName,
              mapColumnIf(
                (col) => col.name === columnName,
                (col) => ({
                  ...col,
                  newType: [col.type], // reset to old type!
                  newUnit: [col.unit], // reset to old unit!
                  status: "error",
                  error: err.message,
                })
              )
            )
          );
        });
    };

    const handleDeleteColumn = (tableName, columnName) => {
      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          mapColumnIf(
            (col) => col.name === columnName,
            (col) => ({ ...col, status: "deleting" })
          )
        )
      );

      deleteStreamField(tableName, columnName)
        .then(() => {
          updateTables(
            mapIf(
              (table) => table.tableName === tableName,
              updateColumns(filter((col) => col.name !== columnName))
            )
          );
          getCurrentUser();
          beamtoast.success(
            `column ${columnName} from ${tableName} stream is deleted successfully`
          );
        })
        .catch((err) => {
          updateTables(
            mapIf(
              (table) => table.tableName === tableName,
              mapColumnIf(
                (col) => col.name === columnName,
                (col) => ({ ...col, status: "error", error: err.message })
              )
            )
          );
          beamtoast.error(
            `Error deleting column ${columnName} from ${tableName} stream`
          );
        });
    };

    return (
      <>
        <Accordion
          fluid
          style={{
            padding: "8px 18px 8px 0px",
          }}
        >
          <Accordion.Title
            active={isActive}
            index={index}
            onClick={() => handleAccordionClick(index)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Icon
                  style={{ fontSize: "20px", alignItems: "center" }}
                  name={
                    index === activeAccordion ? "angle down" : "angle right"
                  }
                />
                <StyledHeader
                  as="h3"
                  style={{
                    margin: "0px",
                    fontSize: "1.1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {highlightText(stream.tableName, searchQuery)}
                </StyledHeader>
                <span style={{ marginLeft: "10px", color: "gray" }}>
                  ({stream.cols.length} columns)
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "12px",
                }}
              >
                {streamError && <p>{streamError}</p>}
                {permissions.editStreams &&
                  stream.tableName !== "device_shadow" &&
                  stream.tableName !== "action_status" && (
                    <DeleteTableModal
                      isStreamDeleting={isStreamDeleting}
                      tableName={stream.tableName}
                      alertRulesStreamName={alertRulesStreamName}
                      dbcParserStreamName={dbcParserStreamName}
                      onConfirm={() => handleDeleteTable(stream.tableName)}
                    />
                  )}
                {permissions.editStreams && (
                  <>
                    {isDBCParserStream ? (
                      <Popup
                        id="add-stream-column-dbc-parser-popup"
                        content={`Cannot add column to stream used as input stream in DBC Parser: ${formatNames(streamDbcParserNames)}`}
                        inverted
                        position="top center"
                        trigger={
                          <Icon link name="add" disabled={isDBCParserStream} />
                        }
                      />
                    ) : (
                      <AddOrEditColumn
                        type="add"
                        trigger={
                          <Popup
                            content="Add new column"
                            inverted
                            position="top center"
                            trigger={
                              <Icon
                                name="add"
                                onClick={() => {
                                  setModalType("add");
                                  setSelectedColumn({
                                    name: "",
                                    type: "",
                                    unit: null,
                                  });
                                  setIsAddOrEditColumnModalOpen(true);
                                }}
                              />
                            }
                          />
                        }
                        isOpen={
                          isAddOrEditColumnModalOpen && modalType === "add"
                        }
                        setIsOpen={setIsAddOrEditColumnModalOpen}
                        tableName={stream.tableName}
                        column={selectedColumn!}
                        onSubmit={handleAddNewColumn}
                        columnNameSet={columnNameSet}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Accordion.Title>
          <Accordion.Content
            active={isActive}
            style={{ padding: "24px 36px 12px 36px" }}
          >
            <Table celled fixed id="streams-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={5} textAlign="center">
                    Column Name
                  </Table.HeaderCell>
                  <Table.HeaderCell width={5} textAlign="center">
                    Column Type
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="center">
                    Column Unit
                  </Table.HeaderCell>
                  {permissions.editStreams && (
                    <Table.HeaderCell width={2} textAlign="center">
                      Options
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {stream.cols.map((col) => {
                  return (
                    <Table.Row key={col.name}>
                      <Table.Cell>
                        {searchQuery ? (
                          highlightText(col.name, searchQuery)
                        ) : (
                          <TextWithToolTip
                            text={col.name}
                            length={60}
                            tooltipposition="top center"
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <ColumnType
                          tableName={stream.tableName}
                          columnName={col.name}
                          required={col.required}
                          status={col.status}
                          pendingType={col.newType}
                          value={col.type}
                          permission={permissions.editStreams}
                          dbcParserStreamName={dbcParserStreamName}
                          onChange={(newType) =>
                            handleEditColumn(
                              stream.tableName,
                              col.name,
                              newType,
                              !isNumericType(newType)
                                ? null
                                : col.newUnit ?? col.unit
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <ColumnUnit
                          required={col.required}
                          status={col.status}
                          pendingUnit={
                            col.newUnit === "null"
                              ? "None"
                              : col.newUnit ?? "None"
                          }
                          value={
                            col.unit === "null" ? "None" : col.unit ?? "None"
                          }
                          columnType={col.type}
                          permission={permissions.editStreams}
                          onChange={(newUnit) =>
                            handleEditColumn(
                              stream.tableName,
                              col.name,
                              col.newType ?? col.type,
                              newUnit
                            )
                          }
                        />
                      </Table.Cell>
                      {permissions.editStreams && (
                        <Table.Cell>
                          {!col.required && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "12px",
                              }}
                            >
                              <AddOrEditColumn
                                type="edit"
                                trigger={
                                  <Icon
                                    link
                                    name="edit"
                                    onClick={() => {
                                      setModalType("edit");
                                      setSelectedColumn(col);
                                      setIsAddOrEditColumnModalOpen(true);
                                    }}
                                  />
                                }
                                isOpen={
                                  isAddOrEditColumnModalOpen &&
                                  modalType === "edit"
                                }
                                setIsOpen={setIsAddOrEditColumnModalOpen}
                                tableName={stream.tableName}
                                column={selectedColumn!}
                                onSubmit={handleEditColumn}
                                columnNameSet={columnNameSet}
                              />

                              <DeleteColModal
                                tableName={stream.tableName}
                                columnName={col.name}
                                dbcParserStreamName={dbcParserStreamName}
                                alertRulesStreamName={alertRulesStreamName}
                                onConfirm={() =>
                                  handleDeleteColumn(stream.tableName, col.name)
                                }
                              />
                            </div>
                          )}
                        </Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>
        <ThinDivider style={{ margin: "0px" }} />
      </>
    );
  }
);

export default function Streams() {
  const { user } = useUser();
  const permissions = user?.role?.permissions ?? {};

  const [tables, setTables] = useState<TableType[]>([]);
  const [alertRulesStreamName, setAlertRulesStreamName] = useState<
    Map<string, string[]>
  >(new Map());
  const [dbcParserStreamName, setDBCParsersStreamName] = useState<
    Map<string, string[]>
  >(new Map());

  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeAccordion, setActiveAccordion] = useState<number>(-1);

  const streamsWithActiveAlertRules = async () => {
    try {
      const alertsRes = await fetchAlertRules();

      const streamWithAlertRules = new Map();
      alertsRes.forEach((alertRule) => {
        if (streamWithAlertRules.has(alertRule.stream)) {
          let alertNames: string[] =
            streamWithAlertRules.get(alertRule.stream) ?? [];
          streamWithAlertRules.set(
            alertRule.stream,
            alertNames.concat(alertRule.name)
          );
        } else {
          streamWithAlertRules.set(alertRule.stream, [alertRule.name]);
        }
      });
      setAlertRulesStreamName(streamWithAlertRules);
    } catch (error) {
      console.error("An error occurred while fetching alert rules:", error);
    }
  };

  const streamsWithDBCParsers = async () => {
    try {
      const dbcRes = await fetchAllDBCs();

      const streamWithDBCParsers = new Map();
      dbcRes.results.forEach((dbc) => {
        if (streamWithDBCParsers.has(dbc.input_table)) {
          let parserNames: string[] =
            streamWithDBCParsers.get(dbc.input_table) ?? [];
          streamWithDBCParsers.set(
            dbc.input_table,
            parserNames.concat(dbc.name)
          );
        } else {
          streamWithDBCParsers.set(dbc.input_table, [dbc.name]);
        }
      });
      setDBCParsersStreamName(streamWithDBCParsers);
    } catch (error) {
      console.error("An error occurred while fetching dbc parsers:", error);
    }
  };

  const filteredTables = tables.filter(
    (table) =>
      table.tableName.toLowerCase().includes(searchQuery) ||
      table.cols.some((col) => col.name.toLowerCase().includes(searchQuery))
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleAccordionClick = (index: number) => {
    setActiveAccordion(activeAccordion === index ? -1 : index);
  };

  const refreshStreams = async () => {
    setLoading(true);
    try {
      let response = await fetchAllStreamsWithDetails(),
        // Filtering the stream table info to remove streams name starting with uplink_ and ending with _local
        // and also filtering out columns ending with _timestamp
        filteredStreamsWithDetails = filterStreamTableInfo(response, true);

      const streamsWithDetails = Object.entries(filteredStreamsWithDetails).map(
        ([streamName, streamDetails]) => {
          let stream = {
            tableName: streamName,
            cols: sortColumns(streamDetails).map((col) => ({
              ...col,
              newType: col.type,
              newUnit: col.unit,
              status: "ready",
            })),
            slatedForDeletion: false,
            status: "ready",
            error: "",
          };
          return stream;
        }
      );

      setTables(streamsWithDetails);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Streams | Bytebeam";
    // To scroll to top of the screen
    window.scrollTo(0, 0);

    const initialFetch = async () => {
      try {
        await refreshStreams();
        await streamsWithActiveAlertRules();
        await streamsWithDBCParsers();
      } catch (e) {
        console.log(e);
        setErrorOccurred(true);
      } finally {
        setLoading(false);
      }
    };

    initialFetch();
  }, []);

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="75vh"
        fontSize="1.5rem"
        loadingText="Loading streams"
      />
    );
  }

  return (
    <CardContainer
      style={{
        paddingBottom: "12px",
      }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={6} style={{ padding: "0px" }}>
            <StyledInput
              icon={{
                name: searchQuery ? "close" : "search",
                link: !!searchQuery,
                onClick: searchQuery
                  ? () => {
                      setSearchQuery("");
                      setActiveAccordion(-1);
                    }
                  : undefined,
              }}
              placeholder="Search by stream or column name..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ width: "100%" }}
              background={"transparent"}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <DisplayIf cond={permissions.editStreams}>
              <CreateStreamModal
                onClose={() => refreshStreams()}
                sourceStreams={tables}
              />
            </DisplayIf>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ padding: "0px" }}>
            {filteredTables.length ? (
              <Virtuoso
                style={{
                  height: "58vh",
                  overflow: "auto",
                }}
                totalCount={filteredTables.length}
                itemContent={(index) => (
                  <StreamRow
                    key={filteredTables[index].tableName}
                    stream={filteredTables[index]}
                    index={index}
                    handleAccordionClick={handleAccordionClick}
                    dbcParserStreamName={dbcParserStreamName}
                    activeAccordion={activeAccordion}
                    alertRulesStreamName={alertRulesStreamName}
                    searchQuery={searchQuery}
                    tables={tables}
                    setTables={setTables}
                  />
                )}
              />
            ) : (
              <ErrorMessage marginTop="6px" message="No streams found" />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CardContainer>
  );
}
